var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "project-detail-task" },
    [
      _c("div", { staticClass: "detail-title", style: "left: -56px" }, [
        _c("img", {
          staticClass: "icon icon-back",
          attrs: { src: "/img/project/icon-back.png", alt: "" },
          on: { click: _vm.backToList },
        }),
        _vm._v("\n    " + _vm._s(_vm.title) + "\n  "),
      ]),
      _c("el-tree", {
        ref: "treeMenu",
        attrs: {
          data: _vm.currentEquipments,
          "show-checkbox": "",
          "node-key": "equipmentId",
          "default-checked-keys": _vm.menuTreeObj,
          props: _vm.treeProps,
        },
        on: { check: _vm.currentCheck },
      }),
      _c("el-tree", {
        ref: "treeMenu",
        attrs: {
          data: _vm.historyEquipments,
          "show-checkbox": "",
          "node-key": "equipmentId",
          "default-checked-keys": _vm.menuTreeObj,
          props: _vm.treeProps,
        },
        on: { check: _vm.historyCheck },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }