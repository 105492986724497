<template>
  <div class="project-detail-task">
    <div
      :style="`left: -56px`"
      class="detail-title">
      <img
        class="icon icon-back"
        @click="backToList"
        src="/img/project/icon-back.png"
        alt=""
      />
      {{ title }}
    </div>
    <el-tree 
      :data="currentEquipments"
      show-checkbox
      node-key="equipmentId"
      ref="treeMenu"
      @check="currentCheck"
      :default-checked-keys="menuTreeObj"
      :props="treeProps">
    </el-tree>
    <el-tree
      :data="historyEquipments"
      show-checkbox
      node-key="equipmentId"
      @check="historyCheck"
      ref="treeMenu"
      :default-checked-keys="menuTreeObj"
      :props="treeProps">
    </el-tree>
  </div>
</template>
<script>
import {
  getEquListByProjectId,
  getProjectDetail
} from "@/api/project/index";

export default {
  name: "taskUav",
  data() {
    return {
      detailInfo: {},
      searchText: '',
      currentEquipments: [
       {
        equipmentId: 1,
        equipmentName: '当前设备',
        children:[

        ]
       }

      ],
      historyEquipments: [
       {
        equipmentId: 1,
        equipmentName: '历史设备',
        children:[

        ]
       }

      ],
      treeProps: {
        label: 'equipmentName',
        children: 'children'
      },
      selectedCurrentEquipments: [],
      selectedHistoryEquipments: [],
    }
  },
  watch: {
    selectedNodes() {
      this.$EventBus.$emit('mission-canter-taskpage', this.selectedNodes)
    }
  },
  computed: {
    title() {
      if (this.$route.query &&  this.$route.query.projectName) {
        return this.$route.query.projectName
      }
      if (this.detailInfo && this.detailInfo.projectName) {
        return this.detailInfo.projectName
      }
      return '' 
    },
    selectedNodes() {
      return this.selectedCurrentEquipments.concat(this.selectedHistoryEquipments) 
      
    }
  },
  created() {
    getProjectDetail({id: this.$route.query.projectId}).then((res) => {
      if (res.data.code === 200) {
        this.detailInfo = res.data.data;
      }
    })
  },
  mounted() {
    this.getEquList();
  },
  methods: {
    getEquList(){
      let projectId = this.$route.query.projectId
      if (!projectId) {
        return
      }
      getEquListByProjectId({projectId}).then((res) => {
        const ce=[];
        res.data.data.currentEquipments.forEach(ele => {
          const eq={
          equipmentId: ele.equipmentId,
          equipmentName: ele.equipmentName
          }   
      
          ce.push(eq);
        });

        const historyE=[];
        if(res.data.data.historyEquipments!==null){
        res.data.data.historyEquipments.forEach(ele => {
          const eq={
          equipmentId: ele.equipmentId,
          equipmentName: ele.equipmentName
          }   
      
          historyE.push(eq);
        });
      }

        this.currentEquipments[0].children=ce;
        this.historyEquipments[0].children=historyE;
      }).catch((err) => {
        setTimeout(() => {
          this.canSubmit = true;
        }, 1000);
      });
    },
    backToList() {
      this.$router.push('/mission-center/item-manager-config');
    },
    edit() {
      console.log("编辑");
    },
    file() {
      console.log("归档");
    },
    remove() {
      console.log("删除");
    },
    currentCheck(node, data) {
      this.selectedCurrentEquipments = data.checkedKeys.filter(item=>{
        return item !== 1
      })
    },
    historyCheck(node, data) {
      this.selectedHistoryEquipments = data.checkedKeys.filter(item=>{
        return item !== 1
      })
    },
    // 关闭弹框，清空表单
    handleCloseDialogForm() {
      // 重置表单
      this.addForm = {
        name: "",
        fly: "",
        load: "",
        airline: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-tree{
  background: #1e222a;
}

::v-deep .bottomText {
  font-size: 12px;
  color: #999999;
}

::v-deep.el-select {
  width: 90%;

  .el-input__inner {
    // width: 16rem;
    background-color: #000000;
    height: auto;
    border: 1px solid #535353;
    color: #fff;
  }

  .el-select-dropdown {
    background: #0f1219 !important;
    border: 1px solid #535353;
  }

  .el-select-dropdown__item.hover {
    background: #0f1219;
  }
}

.project-detail-task {
  height: 100%;
  margin-bottom: 30px;
  position: relative;
  .detail-title {
    position: absolute;
    top: -49px;
    font-size: 14px;
    font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
    font-weight: bold;
    color: #ffffff;
    padding-top: 9px;
    padding-bottom: 11px;
    display: flex;
    align-items: center;
    .icon-back {
      margin-right: 4px;
    }
  }
  .searchCon {
    .search {
      text-align: center;
      margin: 0;
      padding-top: 18px;
      padding-bottom: 12px;
    }

    .line {
      margin: 0;
      padding: 0;
      height: 1px;
      background-color: #030303;
    }
  }
  .container {
    width: 100%;
    background-color: #1e222a;
    padding: 24px 0;
    color: #eee;
    max-height: calc(100% - 260px);
    overflow-y: overlay;
    overflow-x: hidden;

    .line {
      margin: 0;
      padding: 0;
      height: 1px;
      background-color: #030303;
    }

    .radio {
      margin: 0;
      text-align: center;
      padding: 12px 0;
    }

    .list {
      .content {
        margin-top: 8px;

        .el-tag {
          background: #265edd;
        }
      }
    }
  }
}

:deep .custom {
  border-radius: 20px;
}

.addForm {
  :deep .el-form-item {
    .el-input__inner {
      background: transparent !important;
      width: 100%;
      border: 1px solid rgba(255, 255, 255, 0.47);
    }

    .el-select {
      width: 100%;
    }

    .el-select-dropdown {
      background: #0f1219;
    }

    .el-select-dropdown__item.hover {
      background: #0f1219;
    }

    .el-radio-group {
      width: 100%;

      .el-radio {
        width: 33%;
        margin: 0;
        text-align: center;
      }
    }
  }
}</style>
